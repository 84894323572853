export const phraseLibrary = {
    kate: {
        adjectives: [
            "beautiful",
            "emotionally intelligent",
            "caring",
            "stylish",
            "buxom",
            "socially adept",
            "thoughtful",
            "a real looker",
            "compassionate",
            "a great cook",
            "a gourmand",
            "easy to be around",
            "easy to be with",
        ],
        finals: [
            "Dont you forget it!",
            "I love you!",
            "I'll see you soon!",
            "you got a cute butt!",
            "that's the law. I dont make the laws",
        ],
    },
    ethan: {
        adjectives: [
            "smart",
            "adaptable",
            "so F-n tall",
            "mature for your age",
            "gracious beyond your years",
            "great at mingling with all sorts of people",
            "no cap, so cool fam",
            "very understanding",
            "so easy-going",
        ],
        finals: [
            "keep on rockin!",
            "you're doing great",
            "you'll win TI4 one of these days. ;)",
            "you're making us all proud",
            "that's the law. I dont make the laws",
            "your list of people to reach out to for help isn't empty until you've reach out to your uncle Matt",
        ],
    },
    emily: {
        adjectives: [
            "an underappreciated talent in the podcasting world (but not for long)",
            "so funny",
            "able to get along with everyone you're introduced to",
            "have somehow, enviably, learned the art of keeping in touch",
            "so creative",
            "making awesome progress with your pottery",
            "really brave",
            "shockingly well adjusted",
            "fun",
            "full of great stories",
            "good at making plans",
            "adaptable",
        ],
        finals: [
           "they're not ready for you to pop off",
            "that's the law. I dont make the laws",
            "all those terrible things they say about you are surely lies",
            "remember to stay hydrated",
            "keep your place clean, cause im gonna visit",
            "keep on rockin!",
        ],
    },
    ivy: {
        adjectives: [
            "smart",
            "impressing all of us with how well you're doing in scouts",
            "decorating your room in such a stylish way",
            "really do more than you need to",
            "are killin' it in school",
            "such a 'get stuff done' kind of person",
            "caring",
            "such a high achiever",
            "full of interesting things to say",
            "so capable",
            "sure to accomplish incredible things",
        ],
        finals: [
            "keep on kickin butt!",
            "you'll be eagle in no time",
            "be sure to treat yourself like you would treat a friend",
            "that's the law. I dont make the laws",
            "remember to stay hydrated",
            "your list of people to reach out to for help isn't empty until you've reach out to your uncle Matt",
        ],
    },
    violet: {
        adjectives: [
            "have your finger on the pulse of the zietgiest",
            "a good sport when millenials make fun of you",
            "a worthy adversary when making fun of millenials",
            "so creative",
            "independent",
            "cool",
            "someone who 'got that rizz'",
            "too good at smash bros. seriously. stop being so good so i can beat you",
            "pretty dang tall! its a good thing",
            "really impressive for learning japanese",
        ],
        finals: [
            "dont forget to eat lunch",
            "remember: you cannot kill cringe. Only the part of you that cringes.",
            "listen to your parents, its upsetting to admit, but they know some stuff",
            "drink some water!",
            "cant wait to see some of your art",
            "that's the law. I dont make the laws",
            "we're all super proud of you",
            "your list of people to reach out to for help isn't empty until you've reach out to your uncle Matt",
        ],
    },
    gabe: {
        adjectives: [
            "such a GREAT dad",
            "cool",
            "analytical and clever without being annoying.",
            "somehow up to date on hip stuff, desipte what your kids say",
            "a good role model",
            "have always been so incredible at gardening",
            "are inspirational in how you try to take care of your health",
            "working so hard for your family",
        ],
        finals: [
            "that's the law. I dont make the laws",
        ],
    },
    amanda: {
        adjectives: [
            "such a GREAT mom",
            "cool",
            "someone who has great taste in novels",
            "so helpful when someone is feeling bad",
            "working hard to keep the kids (and gabe) on track",
            "the mother of doggos and i think thats fantastic",
            "working so hard for your family",
        ],
        finals: [
            "that's the law. I dont make the laws",
        ],
    },
};